import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange } from '@angular/core';
import { TocService } from 'src/app/shared/services/toc/toc.service';
import { OfflineService } from 'src/app/shared/services/offline/offline.service';
import { ModelToc } from 'src/app/shared/data-model/model-toc';
import { Toc } from 'src/app/shared/data-model';
import { OfflineStatusService } from 'src/app/shared/services/offline/offline-status.service';
import { OfflineContentStatus } from 'src/app/shared/enums/offline-content-status';
import { MatCheckboxChange } from '@angular/material/checkbox/checkbox';
import { forkJoin } from 'rxjs';
import { DocumentTree } from 'src/app/shared/data-model/idb';
import { OfflineIconStatus } from 'src/app/shared/enums/offline-icon-status';
import { DocumentService } from 'src/app/shared/services/offline/dexie/document.service';
import { DocumentTreesService } from 'src/app/shared/services/offline/dexie/document-trees.service';
import { PlatformService, PlatformType } from 'src/app/shared/services/offline/platform.service';
import { EnvironmentService } from 'src/app/shared/services/environment.service';

@Component({
  selector: 'bell-toc-model',
  templateUrl: './toc-model.component.html',
  styleUrls: ['./toc-model.component.scss']
})
export class TocModelComponent implements OnInit, OnChanges {
  @Input() model: string;
  @Input() expirationDate: string;
  @Output() tocLoaded = new EventEmitter<boolean>(true);
  modelToc: ModelToc;
  private modelTree: DocumentTree[];
  public OfflineContentStatus: any = OfflineContentStatus;
  public OfflineIconStatus: typeof OfflineIconStatus = OfflineIconStatus;

  constructor(
    private tocService: TocService,
    public offlineService: OfflineService,
    private offlineStatusService: OfflineStatusService,
    private documentService: DocumentService,
    private documentTreeService: DocumentTreesService,
    private platformService: PlatformService,
    private environmentService: EnvironmentService
  ) { }

  ngOnInit() {
    this.getTableOfContents();
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    Object.keys(changes).forEach(propName => {
      const changedProp = changes[propName];
      const to = changedProp.currentValue;
      if (!changedProp.isFirstChange() && propName !== 'expirationDate') {
        this.model = to;
        this.getTableOfContents();
      }
    });
  }

  selectAll($event) {
    this.setChckedStatus(`/${this.model}`, $event.checked);
  }

  checkSelectAll() {
    this.modelToc.toc.forEach((toc) => {
      if (toc.isChecked !== OfflineContentStatus.None) {
        return true;
      } else {
        return false;
      }
    });
  }

  showCheckbox(name: string) {
    if (this.offlineService.isOfflineCapable) {
      return (!/^LR|FORMS|OTHER|VIDEO|AUDIO|HELP$/.test(name));
    }
    return false;
  }

  private setChckedStatus(path: string, isChecked: boolean) {
    this.offlineStatusService.setModelSelectionStatus(path, isChecked).subscribe((data) => {
      this.offlineStatusService.getModelTree(this.model).subscribe((trees) => {
        this.modelTree = trees;
        this.mapCheckedStatus();
      });
    });
  }

  selectManual($event: MatCheckboxChange, toc: Toc) {
    this.offlineStatusService.setSelectionStatus(`/${toc.model}/${toc.toc}`, $event.checked).subscribe();
    if ($event.checked) {
      if (toc.isChecked === OfflineContentStatus.Full && toc.downloadStatus === OfflineIconStatus.Delete) {
        if (toc.updatable) {
          toc.downloadStatus = OfflineIconStatus.Update;
        } else {
          toc.downloadStatus = OfflineIconStatus.Available;
        }
      } else {
        if (toc.downloadStatus === OfflineIconStatus.Add) {
          toc.isChecked = OfflineContentStatus.None;
        } else if (toc.downloadStatus === OfflineIconStatus.Delete && $event.source.indeterminate) {
          if (toc.isChecked === OfflineContentStatus.Partial) {
            toc.downloadStatus = OfflineIconStatus.Add;
          } else {
            toc.isChecked = OfflineContentStatus.Full;
            toc.downloadStatus = OfflineIconStatus.Available;
          }
        } else {
          toc.downloadStatus = OfflineIconStatus.Add;
        }
      }
    } else {
      if (toc.isChecked === OfflineContentStatus.Full) {
        if (toc.downloadStatus === OfflineIconStatus.Add) {
          toc.downloadStatus = OfflineIconStatus.None;
        } else {
          toc.downloadStatus = OfflineIconStatus.Delete;
          toc.isChecked = OfflineContentStatus.Full;
        }
      } else if (toc.isChecked === OfflineContentStatus.Partial) {
        if (toc.downloadStatus === OfflineIconStatus.Add) {
          toc.downloadStatus = OfflineIconStatus.Delete;
        }
      } else {
        toc.downloadStatus = OfflineIconStatus.None;
      }
    }
  }

  getTableOfContents() {
    if (this.model) {
      const modelToc$ = this.tocService.getModelToc(this.model);
      const getModelTree$ = this.offlineStatusService.getModelTree(this.model);
      const documents$ = this.documentService.getAll();
      const allDocumentTree$ = this.documentTreeService.getAll();
      forkJoin([modelToc$, getModelTree$, documents$, allDocumentTree$]).subscribe(([modelTocs, currentModelTree,
        allDocuments, allDocumentTrees]) => {
        if (modelTocs !== null) {
          this.modelToc = modelTocs;
          this.modelTree = currentModelTree;
          const downloadedDocStrings = currentModelTree.filter((d) => d.usable === 'true' && d.doc && d.doc.length > 0).map(d => d.doc);
          if (downloadedDocStrings.length > 0) {
            const updatedNeededDocs = allDocuments.filter(d => downloadedDocStrings.indexOf(d.name) > -1);
            if (updatedNeededDocs.length > 0) {
              updatedNeededDocs.forEach(d => {
                if (new Date(d.availableUpdate) > d.lastUpdate) {
                  this.setUpdateIcon(d.name, allDocumentTrees);
                }
              });
            }
          }
          this.mapCheckedStatus();
          this.tocLoaded.emit(true);
          // remove filtered manual
          const filterList = ['Help'];
          if (this.platformService.platform === PlatformType.MAUI) {
            filterList.push('Software Downloads');
          }
          this.modelToc.toc = this.modelToc.toc.filter(element => {
            return !filterList.includes(element.manual);
          });
          this.modelToc.toc.forEach(element => {
            element.grouping = Math.floor(parseInt(element.order_number, 0) / 1000);
          });
        }
      });
    }
  }

  private setUpdateIcon(docName: string, allDocumentTrees: DocumentTree[]) {
    let filteredDocTrees = allDocumentTrees.filter(dr => dr.doc === docName);
    filteredDocTrees.forEach(currentDocumentTree => {
      this.modelToc.toc.forEach((toc) => {
        const checkStr = `/${toc.model}/${toc.toc}`;
        if (currentDocumentTree.path.indexOf(checkStr) > -1) {
          // set updatable to use when setting icons
          toc.updatable = true;
        }
      });
    });
  }

  private mapCheckedStatus() {
    this.modelToc.toc.forEach((toc) => {
      const tree = this.modelTree.find((t) => t.path === `/${this.model}/${toc.toc}`);
      if (tree) {
        if (tree.selected === 'true') {
          if (tree.selectedChild === tree.childCount || tree.usableChild === tree.childCount) {
            toc.isChecked = OfflineContentStatus.Full;
          } else if (tree.childCount > 0) {
            if (tree.selectedChild > 0 || tree.usableChild > 0) {
              toc.isChecked = OfflineContentStatus.Partial;
            } else {
              toc.isChecked = OfflineContentStatus.None;
            }
          } else {
            toc.isChecked = OfflineContentStatus.None;
          }
        } else {
          if (tree.selectedChild === tree.childCount || tree.usableChild === tree.childCount) {
            toc.isChecked = OfflineContentStatus.Full;
          } else if (tree.childCount > 0) {
            if (tree.selectedChild > 0 || tree.usableChild > 0) {
              toc.isChecked = OfflineContentStatus.Partial;
            } else {
              toc.isChecked = OfflineContentStatus.None;
            }
          } else {
            toc.isChecked = OfflineContentStatus.None;
          }
        }
      } else {
        toc.isChecked = OfflineContentStatus.None;
      }
    });
    this.checkDownloadStatus();
  }

  private checkDownloadStatus() {
    this.modelToc.toc.forEach((toc) => {
      const tree = this.modelTree.find((t) => t.path === `/${this.model}/${toc.toc}`);
      if (tree) {
        if (toc.isChecked === OfflineContentStatus.Full || toc.isChecked === OfflineContentStatus.Partial) {
          if (tree.usableChild === tree.childCount) {
            toc.usable = true;
            if (tree.downloadChild < tree.childCount && tree.selected !== 'true') {
              toc.downloadStatus = OfflineIconStatus.Delete;
              toc.isChecked = OfflineContentStatus.Full;
            } else if (tree.downloadChild > tree.childCount) {
              toc.downloadStatus = OfflineIconStatus.Add;
              toc.isChecked = OfflineContentStatus.Partial;
            } else {
              if (toc.updatable) {
                toc.downloadStatus = OfflineIconStatus.Update;
              } else {
                const tocModelchildren = this.modelTree.filter(t => t.parent === tree.path);
                tocModelchildren.forEach((child)=>{
                  const tocManualChildren = this.modelTree.filter(t => t.parent === child.path);
                  tocManualChildren.forEach((item) =>{
                    if(item.download === 'true'){
                      toc.downloadStatus = OfflineIconStatus.Add;
                      return;
                    }
                    else {
                      toc.downloadStatus = OfflineIconStatus.Available;
                    }
                  });
                });
              }
            }
          } else if (tree.usableChild > 0) {
            toc.usable = true;
            if (tree.selectedChild > tree.usableChild) {
              toc.downloadStatus = OfflineIconStatus.Add;
              if (tree.selectedChild === tree.childCount) {
                toc.isChecked = OfflineContentStatus.Full;
              } else {
                toc.isChecked = OfflineContentStatus.Partial;
              }
            } else if (tree.selectedChild < tree.usableChild) {
              toc.downloadStatus = OfflineIconStatus.Delete;
              toc.isChecked = OfflineContentStatus.Partial;
            } else {
              if (toc.updatable) {
                toc.downloadStatus = OfflineIconStatus.Update;
              } else {
                toc.downloadStatus = OfflineIconStatus.Available;
              }
            }
          } else {
            toc.usable = false;
            toc.downloadStatus = OfflineIconStatus.Add;
          }
        } else if (toc.isChecked === OfflineContentStatus.None) {
          if (tree.usableChild > 0) {
            toc.usable = true;
          }
          if (tree.selectedChild > 0) {
            toc.downloadStatus = OfflineIconStatus.Add;
            toc.isChecked = OfflineContentStatus.Partial;
          } else {
            toc.downloadStatus = OfflineIconStatus.None;
          }
        }
      }
    });
  }
}
