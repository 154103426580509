import { Injectable } from '@angular/core';
import { Observable, Observer, BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { DocumentTreesService } from './dexie/document-trees.service';
import { DocumentTree, DocumentNode, Icn, OfflineCount, Dm } from '../../data-model/idb';
import { DocumentService } from './dexie/document.service';
import { IcnService } from './dexie/icn.service';
import { GlobalDataService } from './dexie/global-data.service';
import { SpeedTestService } from 'ng-speed-test';
import { PmcService } from './dexie/pmc.service';
import { DmService } from './dexie/dm.service';
import { Pmc } from '../../data-model/idb/pmc';
import { EnvironmentService } from '../environment.service';
import { DownloadFileStatus } from './download/DownloadFileStatus';
import { DownloadDocStatus } from './download/DownloadDocStatus';
import { DocType } from '../../enums/doc-types';
import { HierarchicalList } from './download/HierarchicalList';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineStatusService {
  private isSelectionProgress = false;
  public downloadTimeEstimate$ = new BehaviorSubject<number>(null);
  public userInternetMegabytesPerSecond;
  public updatingDocCounts$ = new Subject<boolean>();
  public totalDocSize: number;
  public resetOfflineCount$ = new Subject<any>();
  public docDownloadFinished = [];
  public icnDownloadFinished = [];
  public diskSpaceAvailable$ = new Subject<string>();
  public diskSpaceDetected: number;
  public isStatusModified$ = new Subject<boolean>();
  private speedTestPath = '';
  private speedTestSize = 6116;
  private allDocumentTrees: DocumentTree[] = [];
  private allDocuments: DocumentNode[] = [];
  private allDms: Dm[] = [];
  private allPms: Pmc[] = [];
  private allIcns: Icn[] = [];
  public resetAllIdxData = true;

  constructor(
    private documentTreeService: DocumentTreesService,
    private documentService: DocumentService,
    private icnService: IcnService,
    private globalService: GlobalDataService,
    private speedTestService: SpeedTestService,
    private pmcService: PmcService,
    private dmService: DmService,
    private environmentService: EnvironmentService,
    private platformService: PlatformService
  ) {
    this.speedTestPath = `${this.environmentService.getBackendUrl()}/png/speedtest?path=speedtest&p=0`;
    setTimeout(() => { this.setUserInternetSpeed(); });
  }

  public setUserInternetSpeed() {
    // using login service to check is logged in creates circular dependency
    if (this.platformService.isOfflineCapable() && sessionStorage.getItem('session_info')) {
      this.speedTestService.getMbps(
        3,
        {
          path: this.speedTestPath,
          size: this.speedTestSize,
          shouldBustCache: true
        }
      ).subscribe(
        (speed) => {
          this.userInternetMegabytesPerSecond = speed * 8;
        }
      );
    }
  }

  public setSelectionStatus(path: string, isChecked: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (!this.isSelectionProgress) {
        this.isSelectionProgress = true;
        this.getAllIdxData().subscribe(r => {
          const trees: DocumentTree[] = [];
          const currentTree = this.allDocumentTrees.find(dt => dt.path === path);
          const allChilds = this.allDocumentTrees.filter(dt => dt.path.startsWith(path + '/') && !dt.path.includes('-PDF'));
          trees.push(currentTree);
          trees.push(...allChilds);
          if (trees && trees.length > 0) {
            this.setSelectedChild(trees, isChecked);
            if (currentTree.parent !== '/') {
              const allParentKeys = this.getAllParentKeys(currentTree.parent);
              if (allParentKeys && allParentKeys.length > 0) {
                allParentKeys.forEach(parentPath => {
                  const thisParent = this.allDocumentTrees.find(dt => dt.path === parentPath);
                  if (thisParent) {
                    trees.push(thisParent);
                  }
                });
              }
              this.setParent(currentTree.parent, trees, this.allDocumentTrees, isChecked);
              this.documentTreeService.updateBulk(trees).subscribe(() => {
                this.isSelectionProgress = false;
                observer.next(true);
                this.isStatusModified$.next(true);
                observer.complete();
              });
            } else {
              this.isSelectionProgress = false;
              observer.next(false);
              observer.complete();
            }
          } else {
            this.isSelectionProgress = false;
            observer.next(false);
            observer.complete();
          }
        });
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  public setModelSelectionStatus(path: string, isChecked: boolean): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (!this.isSelectionProgress) {
        this.isSelectionProgress = true;
        this.getAllIdxData().subscribe(r => {
          const trees: DocumentTree[] = [];
          const currentTree = this.allDocumentTrees.find(dt => dt.path === path);
          const allChilds = this.allDocumentTrees.filter(dt => dt.path.startsWith(path + '/') && !dt.path.includes('-PDF'));
          trees.push(currentTree);
          trees.push(...allChilds);
          if (trees && trees.length > 0) {
            this.setSelectedChild(trees, isChecked);
            if (isChecked) {
              currentTree.selectedChild = currentTree.childCount;
              currentTree.selected = 'true';
              currentTree.download = 'true';
            } else {
              currentTree.selectedChild = 0;
              currentTree.selected = 'false';
              currentTree.download = 'false';
            }
            this.documentTreeService.updateBulk(trees).subscribe(() => {
              this.isSelectionProgress = false;
              observer.next(true);
              this.isStatusModified$.next(true);
              observer.complete();
            });
          } else {
            this.isSelectionProgress = false;
            observer.next(false);
            observer.complete();
          }
        });
      } else {
        observer.next(false);
        observer.complete();
      }
    });
  }

  public setDownloadStaus(trees: DocumentTree[], docs: DocumentNode[], icns: Icn[], allTrees: DocumentTree[]): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      const mts = this.getMissingParents(trees, allTrees);
      if (mts && mts.length > 0) {
        trees = trees.concat(mts);
      }
      // Set the document level nodes first
      const docTrees = trees.filter(t => t.doc !== undefined);
      docTrees.forEach(t => {
        const doc = docs.find(d => d.name === t.doc);
        if (doc) {
          if (doc.selectionCount > 0) {
            if (t.usable === 'true') {
              t.download = 'false';
              t.downloadChild = 0;
              t.remove = 'false';
              t.removeChild = 0;
            }
            if (t.remove === 'true' && t.usable === 'false') {
              t.remove = 'false';
              t.removeChild = 0;
            }
          } else {
            t.usable = 'false';
            t.usableChild = 0;
            t.remove = 'false';
            t.removeChild = 0;
          }
        }
      });
      const toplevelParents = trees.filter(t => t.parent === '/');
      const hierarchicalList: HierarchicalList[] = [];
      hierarchicalList.push({ hierarchy: 1, list: toplevelParents });
      this.getDocumentHierarchicalOrder(hierarchicalList, toplevelParents, trees, 2);
      while (hierarchicalList.length > 0) {
        const leafNode = hierarchicalList.pop();
        if (leafNode.list && leafNode.list.length > 0) {
          leafNode.list.forEach(lt => {
            if (lt.doc === undefined || lt.doc === '') {
              const downloadedDocCount = allTrees.filter(t => t.parent === lt.path && t.doc !== undefined && t.usable === 'true').length;
              const downloadedTreeCount = allTrees.filter(t => t.parent === lt.path && t.doc === undefined
                && t.usableChild === t.childCount).length;
              const downloadedPartialTreeCount = allTrees.filter(t => t.parent === lt.path && t.doc === undefined
                && t.usableChild > 0 && t.usableChild !== t.childCount).length;
              const downloadedCount = downloadedDocCount + downloadedTreeCount;

              if (downloadedCount === lt.childCount) {
                lt.usable = 'true';
                lt.usableChild = lt.childCount;
                lt.remove = 'false';
                lt.removeChild = 0;
                lt.download = 'false';
                lt.downloadChild = 0;
              } else {
                if (downloadedCount + downloadedPartialTreeCount === 0) {
                  lt.usable = 'false';
                  lt.remove = 'false';
                  lt.usableChild = 0;
                  lt.removeChild = 0;
                } else if (lt.remove === 'true') {
                  if (downloadedCount + downloadedPartialTreeCount === 0) {
                    lt.remove = 'false';
                    lt.removeChild = 0;
                  } else {
                    lt.usable = 'true';
                    lt.remove = 'false';
                    lt.usableChild = downloadedCount + downloadedPartialTreeCount * .5 ;
                  }
                } else if (downloadedCount + downloadedPartialTreeCount > 0) {
                  lt.usable = 'true';
                  lt.usableChild = downloadedCount + downloadedPartialTreeCount * .5 ;
                }
              }
            }
          });
        }
      }

      const update$: Observable<Boolean>[] = [];
      if (docs.length > 0) {
        update$.push(this.documentService.updateBulk(docs));
      }
      if (icns.length > 0) {
        update$.push(this.icnService.updateBulk(icns));
      }
      if (trees.length > 0) {
        update$.push(this.documentTreeService.updateBulk(trees));
      }
      if (update$.length > 0) {
        forkJoin(update$).subscribe((updateStatuses: boolean[]) => {
          observer.next(!updateStatuses.includes(false));
          observer.complete();
        });
      } else {
        observer.next(false);
      }
    });
  }

  private getDocumentHierarchicalOrder(hierarchicalList: HierarchicalList[], topLevelTrees: DocumentTree[],
    trees: DocumentTree[], currentHierarchy: number) {
    const nextTopLevelTrees: DocumentTree[] = [];
    topLevelTrees.forEach(tr => {
      const childTrees = trees.filter(t => t.parent === tr.path);
      if (childTrees && childTrees.length > 0) {
        const existingHrList = hierarchicalList.find(hrl => hrl.hierarchy === currentHierarchy);
        if (existingHrList) {
          existingHrList.list.push(...childTrees);
        } else {
          hierarchicalList.push({ hierarchy: currentHierarchy, list: childTrees });
        }
        childTrees.forEach(ct => {
          if (ct.doc === undefined || ct.doc === '') {
            nextTopLevelTrees.push(ct);
          }
        });
      }
    });
    if (nextTopLevelTrees.length > 0) {
      this.getDocumentHierarchicalOrder(hierarchicalList, nextTopLevelTrees, trees, currentHierarchy + 1);
    }
  }

  public getDownloadCounts(): Observable<OfflineCount> {
    return new Observable((observer: Observer<OfflineCount>) => {
      const offlineCount = new OfflineCount(0, 0, 0, 0);
      this.globalService.getByKey('lastCheck').subscribe((gd) => {
        if (gd && gd.data) {
          this.getAllIdxData().subscribe(r => {
            const downloadFileStatuses: DownloadFileStatus[] = [];
            const downloadDocStatuses: DownloadDocStatus[] = [];
            const updateNeededDocStrings: string[] = [];
            const selectedTrees = this.allDocumentTrees.filter((d) => d.download === 'true' && d.usable === 'false'
              && d.doc && d.doc.length > 0);
            const downloadedDocStrings = this.allDocumentTrees.filter((d) => d.usable === 'true' && d.doc
              && d.doc.length > 0).map(d => d.doc);
            if (downloadedDocStrings.length > 0) {
              const updatedNeededDocs = this.allDocuments.filter(d => downloadedDocStrings.indexOf(d.name) > -1);
              if (updatedNeededDocs.length > 0) {
                updatedNeededDocs.forEach(d => {
                  if (new Date(d.availableUpdate) > d.lastUpdate) {
                    updateNeededDocStrings.push(d.name);
                  }
                });
              }
                // && new Date(d.availableUpdate) > d.lastUpdate).map(dc => dc.name);
              if (updateNeededDocStrings.length > 0) {
                const updateNeededTrees = this.allDocumentTrees.filter(t => updateNeededDocStrings.indexOf(t.doc) > -1);
                selectedTrees.push(...updateNeededTrees);
              }
            }
            this.getMappedDocumentAndIcns(selectedTrees, updateNeededDocStrings, this.allDocuments, downloadFileStatuses,
              downloadDocStatuses, this.allDms, this.allPms, this.allIcns);
            offlineCount.added = downloadFileStatuses.filter(d => d.isIcn === false && d.isUpdate === false).length;
            offlineCount.additionalFiles = downloadFileStatuses.filter(d => d.isIcn === true).length;
            offlineCount.totalSize = downloadFileStatuses.reduce((a, b) => +a + +b.size, 0);
            offlineCount.willBeDeleted = this.allDocumentTrees.filter((d) => d.remove === 'true' && d.doc && d.doc.length > 0).length;
            offlineCount.willUpdate = downloadFileStatuses.filter(d => d.isUpdate && d.isIcn === false).length;
            offlineCount.uptoDate = downloadedDocStrings.length - offlineCount.willUpdate;

            const addedByteSizeString = this.handleDocAddedBytes(offlineCount.totalSize);
            const addedbyteSizeNum = Number(this.handleDocAddedBytesNum(offlineCount.totalSize));
            offlineCount.totalSizeString = addedByteSizeString;
            this.getUpdateTimeEstimate(addedByteSizeString, addedbyteSizeNum);
            observer.next(offlineCount);
            observer.complete();
          });
        }
      });
    });
  }

  public getMappedDocumentAndIcns(trees: DocumentTree[], updatableDocuments: string[], allDocuments: DocumentNode[]
    , downloadFileStatuses: DownloadFileStatus[], downloadDocStatuses: DownloadDocStatus[], dms: Dm[], pmcs: Pmc[], icns: Icn[]) {
    const docs: string[] = [];
    trees.forEach((tree) => {
      if (tree.doc && tree.doc.length > 0) {
        docs.push(tree.doc);
      }
    });

    if (docs.length > 0) {
      const selectedDocs: DocumentNode[] = [];
      docs.forEach(dn => {
        const filteredDocs = allDocuments.filter(d => d.name === dn);
        selectedDocs.push(...filteredDocs);
      });
      // const selectedDocs = documents.filter(d => docs.indexOf(d.name) > -1);
      if (selectedDocs.length > 0) {
        selectedDocs.forEach((doc) => {
          const existingFileStatus = downloadFileStatuses.find((dfs) => dfs.fileName === doc.name);
          if (!existingFileStatus) {
            const isExisting = updatableDocuments.indexOf(doc.name) > -1;
            downloadFileStatuses.push({
              fileName: doc.name, isIcn: false,
              size: doc.size, document: doc, icn: undefined, isComplete: false, isS1000D: doc.type === 'S1000D', isUpdate: isExisting
            });
            downloadDocStatuses.push({
              documentName: doc.name, document: doc, idFile: doc.idFile, idManual: doc.idManual,
              isIcn: doc.type === DocType.S1000D, isComplete: false, isUpdate: isExisting, downloadIcnStatuses: []
            });
          }
        });

        if (downloadDocStatuses.length > 0) {
          const icnDocs = downloadDocStatuses.filter(d => d.isIcn === true);
          if (icnDocs.length > 0) {
            this.mapIcns(icnDocs, downloadFileStatuses, dms, pmcs, icns);
          }
        }
      }
    }
  }

  private mapIcns(downloadDocStatuses: DownloadDocStatus[], downloadFileStatuses: DownloadFileStatus[],
    dms: Dm[], pmcs: Pmc[], icns: Icn[]) {
    downloadDocStatuses.forEach(downloadDoc => {
      const data = dms.filter(d => d.idFile === downloadDoc.idFile && d.idManual === downloadDoc.idManual);
      const pubCodes: string[] = [];
      const icnStrings: string[] = [];
      data.forEach((dm) => {
        if (!pubCodes.includes(dm.code)) {
          pubCodes.push(dm.code);
        }
      });

      if (pubCodes.length > 0) {
        const pmc = pmcs.filter(p => pubCodes.indexOf(p.pubCode) > -1);

        pmc.forEach((pm) => {
          if (pm.icns.length > 0) {
            pm.icns.forEach((currentIcn) => {
              if (!icnStrings.includes(currentIcn.icn)) {
                icnStrings.push(currentIcn.icn);
              }
            });
          }
        });

        const currentIcns = icns.filter(ic => icnStrings.indexOf(ic.name) > -1);
        currentIcns.forEach(currentIcn => {
          const currentFile = downloadFileStatuses.find(df => df.fileName === currentIcn.name);
          if (!currentFile) {
            downloadFileStatuses.push({
              fileName: currentIcn.name, isIcn: true, size: currentIcn.size,
              document: downloadDoc.document, icn: currentIcn, isComplete: false, isUpdate: downloadDoc.isUpdate, isS1000D: false
            });
          }
          downloadDoc.downloadIcnStatuses.push({ icnName: currentIcn.name, isComplete: false });
        });
      }
    });
  }

  public sendDiskSpace(checkedDiskSpaceAvailable) {
    this.diskSpaceDetected = checkedDiskSpaceAvailable;
  }

  public sendResetOfflineCount(offlineCount: OfflineCount, size: number, completedDocs: number, deletedDocs: number,
    isSoftDelete: boolean, isIcn: boolean, isUpdate: boolean) {
    offlineCount.added -= completedDocs;
    offlineCount.uptoDate += completedDocs;
    offlineCount.willBeDeleted -= deletedDocs;

    if (isUpdate) {
      offlineCount.willUpdate -= completedDocs;
    }

    if (isIcn) {
      offlineCount.additionalFiles -= 1;
    }
    if (!isSoftDelete) {
      offlineCount.uptoDate -= deletedDocs;
    }
    offlineCount.totalSize -= size;
    offlineCount.totalSizeString = this.handleDocAddedBytes(offlineCount.totalSize);
    this.resetOfflineCount$.next(offlineCount);
  }

  private getAllIdxData(): Observable<boolean> {
    return new Observable((observer: Observer<boolean>) => {
      if (this.resetAllIdxData) {
        const documentTrees$ = this.documentTreeService.getAll();
        const documents$ = this.documentService.getAll();
        const pms$ = this.pmcService.getAll();
        const dms$ = this.dmService.getAll();
        const icn$ = this.icnService.getAll();
        forkJoin([documentTrees$, documents$, pms$, dms$, icn$]).subscribe(([documentTrees, documents, pms, dms, icns]) => {
          this.allDocumentTrees = documentTrees;
          this.allDocuments = documents;
          this.allPms = pms;
          this.allDms = dms;
          this.allIcns = icns;
          this.resetAllIdxData = false;
          observer.next(true);
          observer.complete();
        });
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }

  public getModelTree(modelId: string): Observable<DocumentTree[]> {
    return new Observable((observer: Observer<DocumentTree[]>) => {
      this.getTree(`/${modelId}`).subscribe((data) => {
        observer.next(data);
        observer.complete();
      });
    });
  }

  public getModelsTree(model: string[]): Observable<DocumentTree[]> {
    return new Observable((observer: Observer<DocumentTree[]>) => {
      this.getAllIdxData().subscribe(r => {
        const modelTrees: DocumentTree[] = [];
        model.forEach((m) => {
          modelTrees.push(this.allDocumentTrees.find(at => at.path === '/' + m));
        });
        observer.next(modelTrees);
        observer.complete();
      });
    });
  }

  public getManualTree(modelId: string, manualId: string): Observable<DocumentTree[]> {
    return new Observable((observer: Observer<DocumentTree[]>) => {
      this.getTree(`/${modelId}/${manualId}`).subscribe((data) => {
        observer.next(data);
        observer.complete();
      });
    });
  }

  private getMissingParents(trees: DocumentTree[], allTrees: DocumentTree[]): DocumentTree[] {
    const parentTrees: DocumentTree[] = [];
    const parentTreeStrings: string[] = [];
    trees.forEach((t) => {
      if (t.parent !== '/') {
        const parentPaths = this.getAllParentKeys(t.path);
        if (parentPaths.length > 0) {
          parentPaths.forEach(p => {
            const isParentExists = trees.findIndex(st => st.path === p) !== -1 || parentTreeStrings.includes(p);
            if (!isParentExists) {
              parentTreeStrings.push(p);
            }
          });
        }
      }
    });
    if (parentTreeStrings.length > 0) {
      parentTreeStrings.forEach(tp => {
        const parentTree = allTrees.find(t => t.path === tp);
        if (parentTree) {
          parentTrees.push(parentTree);
        }
      });
    }
    return parentTrees;
  }

  private getAllParentKeys(path: string): string[] {
    let parents = [];
    parents.push(path);
    if (path.length > 1 && path.lastIndexOf('/') > 0) {
      parents = parents.concat(this.getAllParentKeys(path.substring(0, path.lastIndexOf('/'))));
    }
    return parents;
  }

  private setSelectedChild(trees: DocumentTree[], isChecked: boolean) {
    trees.forEach((tree) => {
      const children = trees.filter((t) => t.parent === tree.path);
      if (children && children.length > 0) {
        const selectedCount = children.length;
        if (!isChecked) {
          tree.selected = 'false';
          tree.download = 'false';
          tree.selectedChild = 0;
          tree.downloadChild = 0;
          if (tree.usable === 'true') {
            tree.remove = 'true';
            tree.removeChild = selectedCount;
          }
        } else {
          tree.selected = (tree.childCount === selectedCount) ? 'true' : 'false';
          tree.download = (tree.childCount === selectedCount) ? 'true' : 'false';
          tree.selectedChild = selectedCount;
          tree.downloadChild = selectedCount;
          tree.remove = 'false';
          tree.removeChild = 0;
        }
      } else {
        tree.selected = isChecked ? 'true' : 'false';
        tree.download = isChecked ? 'true' : 'false';
        tree.selectedChild = 0;
        tree.downloadChild = 0;
        tree.removeChild = 0;
        if (!isChecked && tree.usable === 'true') {
          tree.remove = 'true';
        }
        if (isChecked && tree.usable === 'true' && tree.remove === 'true') {
          tree.remove = 'false';
          tree.download = 'false';
        }
      }
    });
  }

  private setParent(parentPath: string, allSelectedTrees: DocumentTree[], allTrees: DocumentTree[],
    isChecked: boolean) {
    const parentTree = allSelectedTrees.find((t) => t.path === parentPath);
    const selectedCount = this.getSelectedCount(parentPath, allTrees);
    parentTree.selectedChild = selectedCount;
    parentTree.downloadChild = selectedCount;
    if (!isChecked) {
      parentTree.selected = 'false';
    } else {
      parentTree.selected = (parentTree.childCount === parentTree.selectedChild) ? 'true' : 'false';
      parentTree.download = (parentTree.childCount === parentTree.downloadChild) ? 'true' : 'false';
    }
    if (parentTree.selectedChild < 0) {
      parentTree.selectedChild = 0;
    }
    if (parentTree.parent !== '/') {
      this.setParent(parentTree.parent, allSelectedTrees, allTrees, isChecked);
    }
  }

  private getSelectedCount(parentPath: string, allTrees: DocumentTree[]) {
    const childTrees = allTrees.filter(t => t.parent === parentPath);
    let selectedCount = 0;
    if (childTrees && childTrees.length > 0) {
      childTrees.forEach(ct => {
        if (ct.childCount === 0) {
          if (ct.selected === 'true') {
            selectedCount += 1;
          }
        } else {
          if (ct.selectedChild > 0) {
            if (ct.childCount === ct.selectedChild) {
              selectedCount += 1;
            } else {
              selectedCount += .5;
            }
          }
        }
      });
    }
    return selectedCount;
  }

  private getTree(path: string): Observable<DocumentTree[]> {
    return new Observable((observer: Observer<DocumentTree[]>) => {
      this.getAllIdxData().subscribe(r => {
        const trees: DocumentTree[] = [];
        const currentTree = this.allDocumentTrees.find(at => at.path === path);
        const allChilds = this.allDocumentTrees.filter(at => at.path.startsWith(path + '/') && !at.path.includes('-PDF'));
        if (currentTree) {
          trees.push(currentTree);
        }

        if (allChilds && allChilds.length > 0) {
          trees.push(...allChilds);
        }

        observer.next(trees);
        observer.complete();
      });
    });
  }

  private handleDocAddedBytes(a, b = 2) {
    if (0 === a) {
      return '0 Bytes';
    }
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return parseFloat((a / Math.pow(1024, d)).toFixed(c)) + ' ' + ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d];
  }

  private handleDocAddedBytesNum(a, b = 2) {
    if (0 === a) {
      return '0 Bytes';
    }
    const c = 0 > b ? 0 : b,
      d = Math.floor(Math.log(a) / Math.log(1024));
    return ((a / Math.pow(1024, d)).toFixed(c));
  }

  public getUpdateTimeEstimate(byteSizeString: string, byteSizeNum: number) {
    const sizeString = byteSizeString;
    const sizeNum = byteSizeNum;
    // Calculate seconds for each type of file depending on type passed in byteSizeString, timeout for loading internet speed.
    setTimeout(() => {
      if (!this.userInternetMegabytesPerSecond) {
        // send null if error occurs or speed is not available
        this.sendDownloadTime(null);
        return;
      }
      if (this.totalDocSize < 1024) {
        const seconds = ((sizeNum / 1048576) / this.userInternetMegabytesPerSecond);
        this.sendDownloadTime(seconds);
      }
      if (sizeString && sizeString.indexOf('K') >= 0) {
        const seconds = ((sizeNum / 1000) / this.userInternetMegabytesPerSecond);
        this.sendDownloadTime(seconds);
      }
      if (sizeString && sizeString.indexOf('M') >= 0) {
        const seconds = sizeNum / this.userInternetMegabytesPerSecond;
        this.sendDownloadTime(seconds);
      }
      if (sizeString && sizeString.indexOf('G') >= 0) {
        const seconds = ((sizeNum * 1000) / this.userInternetMegabytesPerSecond);
        this.sendDownloadTime(seconds);
      }
      if (sizeString && sizeString.indexOf('T') >= 0) {
        const seconds = ((sizeNum * 1048576) / this.userInternetMegabytesPerSecond);
        this.sendDownloadTime(seconds);
      }
    }, 5000);
  }

  private sendDownloadTime(value: number) {
    this.downloadTimeEstimate$.next(value);
  }
}
